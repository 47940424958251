export interface NutsItem {
  id: string;
  name: string;
  spc: string;
}

export default [
  { id: 'CZ01', name: 'Praha', spc: 'CZ0100' },
  { id: 'CZ010', name: 'Hlavní město Praha', spc: 'CZ0110' },
  { id: 'CZ0100', name: 'Praha', spc: 'CZ010' },
  { id: 'CZ02', name: 'Střední Čechy', spc: 'CZ0200' },
  { id: 'CZ020', name: 'Středočeský kraj', spc: 'CZ0210' },
  { id: 'CZ0201', name: 'Benešov', spc: 'CZ0211' },
  { id: 'CZ0202', name: 'Beroun', spc: 'CZ0212' },
  { id: 'CZ0203', name: 'Kladno', spc: 'CZ0213' },
  { id: 'CZ0204', name: 'Kolín', spc: 'CZ0214' },
  { id: 'CZ0205', name: 'Kutná Hora', spc: 'CZ0215' },
  { id: 'CZ0206', name: 'Mělník', spc: 'CZ0216' },
  { id: 'CZ0207', name: 'Mladá Boleslav', spc: 'CZ0217' },
  { id: 'CZ0208', name: 'Nymburk', spc: 'CZ0218' },
  { id: 'CZ0209', name: 'Praha-východ', spc: 'CZ0219' },
  { id: 'CZ020A', name: 'Praha-západ', spc: 'CZ021A' },
  { id: 'CZ020B', name: 'Příbram', spc: 'CZ021B' },
  { id: 'CZ020C', name: 'Rakovník', spc: 'CZ021C' },
  { id: 'CZ03', name: 'Jihozápad', spc: 'CZ0300' },
  { id: 'CZ031', name: 'Jihočeský kraj', spc: 'CZ0310' },
  { id: 'CZ0311', name: 'České Budějovice', spc: 'CZ0311' },
  { id: 'CZ0312', name: 'Český Krumlov', spc: 'CZ0312' },
  { id: 'CZ0313', name: 'Jindřichův Hradec', spc: 'CZ0313' },
  { id: 'CZ0314', name: 'Písek', spc: 'CZ0314' },
  { id: 'CZ0315', name: 'Prachatice', spc: 'CZ0315' },
  { id: 'CZ0316', name: 'Strakonice', spc: 'CZ0316' },
  { id: 'CZ0317', name: 'Tábor', spc: 'CZ0317' },
  { id: 'CZ032', name: 'Plzeňský kraj', spc: 'CZ0320' },
  { id: 'CZ0321', name: 'Domažlice', spc: 'CZ0321' },
  { id: 'CZ0322', name: 'Klatovy', spc: 'CZ0322' },
  { id: 'CZ0323', name: 'Plzeň-město', spc: 'CZ0323' },
  { id: 'CZ0324', name: 'Plzeň-jih', spc: 'CZ0324' },
  { id: 'CZ0325', name: 'Plzeň-sever', spc: 'CZ0325' },
  { id: 'CZ0326', name: 'Rokycany', spc: 'CZ0326' },
  { id: 'CZ0327', name: 'Tachov', spc: 'CZ0327' },
  { id: 'CZ04', name: 'Severozápad', spc: 'CZ0400' },
  { id: 'CZ041', name: 'Karlovarský kraj', spc: 'CZ0410' },
  { id: 'CZ0411', name: 'Cheb', spc: 'CZ0411' },
  { id: 'CZ0412', name: 'Karlovy Vary', spc: 'CZ0412' },
  { id: 'CZ0413', name: 'Sokolov', spc: 'CZ0413' },
  { id: 'CZ042', name: 'Ústecký kraj', spc: 'CZ0420' },
  { id: 'CZ0421', name: 'Děčín', spc: 'CZ0421' },
  { id: 'CZ0422', name: 'Chomutov', spc: 'CZ0422' },
  { id: 'CZ0423', name: 'Litoměřice', spc: 'CZ0423' },
  { id: 'CZ0424', name: 'Louny', spc: 'CZ0424' },
  { id: 'CZ0425', name: 'Most', spc: 'CZ0425' },
  { id: 'CZ0426', name: 'Teplice', spc: 'CZ0426' },
  { id: 'CZ0427', name: 'Ústí nad Labem', spc: 'CZ0427' },
  { id: 'CZ05', name: 'Severovýchod', spc: 'CZ0500' },
  { id: 'CZ051', name: 'Liberecký kraj', spc: 'CZ0510' },
  { id: 'CZ0511', name: 'Česká Lípa', spc: 'CZ0511' },
  { id: 'CZ0512', name: 'Jablonec nad Nisou', spc: 'CZ0512' },
  { id: 'CZ0513', name: 'Liberec', spc: 'CZ0513' },
  { id: 'CZ0514', name: 'Semily', spc: 'CZ0514' },
  { id: 'CZ052', name: 'Královéhradecký kraj', spc: 'CZ0520' },
  { id: 'CZ0521', name: 'Hradec Králové', spc: 'CZ0521' },
  { id: 'CZ0522', name: 'Jičín', spc: 'CZ0522' },
  { id: 'CZ0523', name: 'Náchod', spc: 'CZ0523' },
  { id: 'CZ0524', name: 'Rychnov nad Kněžnou', spc: 'CZ0524' },
  { id: 'CZ0525', name: 'Trutnov', spc: 'CZ0525' },
  { id: 'CZ053', name: 'Pardubický kraj', spc: 'CZ0530' },
  { id: 'CZ0531', name: 'Chrudim', spc: 'CZ0531' },
  { id: 'CZ0532', name: 'Pardubice', spc: 'CZ0532' },
  { id: 'CZ0533', name: 'Svitavy', spc: 'CZ0533' },
  { id: 'CZ0534', name: 'Ústí nad Orlicí', spc: 'CZ0534' },
  { id: 'CZ06', name: 'Jihovýchod', spc: 'CZ0600' },
  { id: 'CZ063', name: 'Kraj Vysočina', spc: 'CZ0610' },
  { id: 'CZ0631', name: 'Havlíčkův Brod', spc: 'CZ0611' },
  { id: 'CZ0632', name: 'Jihlava', spc: 'CZ0612' },
  { id: 'CZ0633', name: 'Pelhřimov', spc: 'CZ0613' },
  { id: 'CZ0634', name: 'Třebíč', spc: 'CZ0614' },
  { id: 'CZ0635', name: 'Žďár nad Sázavou', spc: 'CZ0615' },
  { id: 'CZ064', name: 'Jihomoravský kraj', spc: 'CZ0620' },
  { id: 'CZ0641', name: 'Blansko', spc: 'CZ0621' },
  { id: 'CZ0642', name: 'Brno-město', spc: 'CZ0622' },
  { id: 'CZ0643', name: 'Brno-venkov', spc: 'CZ0623' },
  { id: 'CZ0644', name: 'Břeclav', spc: 'CZ0624' },
  { id: 'CZ0645', name: 'Hodonín', spc: 'CZ0625' },
  { id: 'CZ0646', name: 'Vyškov', spc: 'CZ0626' },
  { id: 'CZ0647', name: 'Znojmo', spc: 'CZ0627' },
  { id: 'CZ07', name: 'Střední Morava', spc: 'CZ0700' },
  { id: 'CZ071', name: 'Olomoucký kraj', spc: 'CZ0710' },
  { id: 'CZ0711', name: 'Jeseník', spc: 'CZ0711' },
  { id: 'CZ0712', name: 'Olomouc', spc: 'CZ0712' },
  { id: 'CZ0713', name: 'Prostějov', spc: 'CZ0713' },
  { id: 'CZ0714', name: 'Přerov', spc: 'CZ0714' },
  { id: 'CZ0715', name: 'Šumperk', spc: 'CZ0715' },
  { id: 'CZ072', name: 'Zlínský kraj', spc: 'CZ0720' },
  { id: 'CZ0721', name: 'Kroměříž', spc: 'CZ0721' },
  { id: 'CZ0722', name: 'Uherské Hradiště', spc: 'CZ0722' },
  { id: 'CZ0723', name: 'Vsetín', spc: 'CZ0723' },
  { id: 'CZ0724', name: 'Zlín', spc: 'CZ0724' },
  { id: 'CZ08', name: 'Moravskoslezsko', spc: 'CZ0800' },
  { id: 'CZ080', name: 'Moravskoslezský kraj', spc: 'CZ0810' },
  { id: 'CZ0801', name: 'Bruntál', spc: 'CZ0811' },
  { id: 'CZ0802', name: 'Frýdek-Místek', spc: 'CZ0812' },
  { id: 'CZ0803', name: 'Karviná', spc: 'CZ0813' },
  { id: 'CZ0804', name: 'Nový Jičín', spc: 'CZ0814' },
  { id: 'CZ0805', name: 'Opava', spc: 'CZ0815' },
  { id: 'CZ0806', name: 'Ostrava-město', spc: 'CZ0816' },
] as NutsItem[];
